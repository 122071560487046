import {} from '../app'
import Swiper, {Navigation, Parallax} from 'swiper'

Swiper.use([Navigation, Parallax])
import {gsap} from "../mods/gsap";


// slider
new Swiper('[data-swiper="team"]', {
    speed: 1500,
    slidesPerView: 5,
    spaceBetween: 20,
    parallax: true,
    navigation: {
        nextEl: '.next-team',
        prevEl: '.prev-team',
    },
    breakpoints: {
        1000: {
            slidesPerView: 5
        },
        550: {
            slidesPerView: 2
        },
        300: {
            slidesPerView: 1
        }
    }
})

// slider
new Swiper('[data-swiper="news"]', {
    speed: 1500,
    slidesPerView: 4,
    spaceBetween: 20,
    parallax: true,
    navigation: {
        nextEl: '.next-team',
        prevEl: '.prev-team',
    },
    breakpoints: {
        1000: {
            slidesPerView: 4
        },
        550: {
            slidesPerView: 2
        },
        300: {
            slidesPerView: 1
        }
    }
})